import Apis from '@/config/fetch.js';

export default $axios => {
  let homeMgr=Apis($axios).apiList().home;
  let productMgr=Apis($axios).apiList().product;
  let newsMgr=Apis($axios).apiList().news;
  return {
    homeMgr:{
       getList:function(){
           return homeMgr.getList({life}).then((res)=>{
              return res.result;
           });
       },
       newsList:function(){
           return homeMgr.newsList({life}).then((res)=>{
              return res.result;
           });
       },
    },
    productMgr:{
      getList:function(life){
         return productMgr.getList({life}).then((res)=>{
            return res.result;
         });
      },
    },
    newsMgr:{
        getList:function(life){
           return newsMgr.getList().then((res)=>{
              return res.result;
           });
        },
    }
  };
};
