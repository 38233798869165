import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _48ea2027 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _8a35c9d8 = () => interopDefault(import('../pages/answer.vue' /* webpackChunkName: "pages/answer" */))
const _5840a9ae = () => interopDefault(import('../pages/news.vue' /* webpackChunkName: "pages/news" */))
const _5e073009 = () => interopDefault(import('../pages/product.vue' /* webpackChunkName: "pages/product" */))
const _3c616d68 = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _68a1faec = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _48ea2027,
    name: "about"
  }, {
    path: "/answer",
    component: _8a35c9d8,
    name: "answer"
  }, {
    path: "/news",
    component: _5840a9ae,
    name: "news"
  }, {
    path: "/product",
    component: _5e073009,
    name: "product"
  }, {
    path: "/test",
    component: _3c616d68,
    name: "test"
  }, {
    path: "/",
    component: _68a1faec,
    name: "index"
  }, {
    path: "/index.html",
    component: _68a1faec,
    name: "IndexPage"
  }, {
    path: "/product.html",
    component: _5e073009,
    name: "ProductPage"
  }, {
    path: "/news.html",
    component: _5840a9ae,
    name: "NewsPage"
  }, {
    path: "/about.html",
    component: _48ea2027,
    name: "AboutPage"
  }, {
    path: "/answer.html",
    component: _8a35c9d8,
    name: "AnswerPage"
  }, {
    path: "/test.html",
    component: _3c616d68,
    name: "TestPage"
  }],

  fallback: true
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
