
  export default{
    mounted(){

      var userAgent = navigator.userAgent;
      var isIE=window.ActiveXObject || "ActiveXObject" in window;
      if (!isIE){
        // 在线咨询
        window.onload=function(){
          (function(b,a,e,h,f,c,g,s){b[h]=b[h]||function(){(b[h].c=b[h].c||[]).push(arguments)};
          b[h].s=!!c;g=a.getElementsByTagName(e)[0];s=a.createElement(e);
          s.src="//s.union.360.cn/"+f+".js";s.defer=!0;s.async=!0;g.parentNode.insertBefore(s,g)
          })(window,document,"script","_qha",480573,false);
        };
      }

      //百度统计
      var _hmt = _hmt || [];
      (function() {
        var hm = document.createElement("script");
        hm.src = "//hm.baidu.com/hm.js?0691369a48b75b3258caea67fae9db51";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
      })();

      //360统计
      (function(b,a,e,h,f,c,g,s){b[h]=b[h]||function(){(b[h].c=b[h].c||[]).push(arguments)};
      b[h].s=!!c;g=a.getElementsByTagName(e)[0];s=a.createElement(e);
      s.src="//s.union.360.cn/"+f+".js";s.defer=!0;s.async=!0;g.parentNode.insertBefore(s,g)
      })(window,document,"script","_qha",480573,false);
    }
  }
