import server from "@/utils/server/index.js";
let {
  get,
  post,
} = server;

let app = {
  home: {
    getList: get('/cate/life'),
    newsList: post('/news/list'),
  },
  product: {
    getList: post("/cate/[life]"),
  },
  news: {
    getList: post("/news/list"),
  },
};



export default $axios => ({
   apiList:function(){
       let list={};
       for(let key in app){
          list[key]={};
          for(let subKey in app[key]){
              list[key][subKey] = (params) => {
                  let apiObj=app[key][subKey];

                  //url处理
                  let path = apiObj.url;
                  if (apiObj.url.indexOf('[') > 0) {
                    for (let k in params) {
                      path = path.replace(`[${k}]`, params[k]);
                    }
                  }

                  //请求
                  return $axios({
                    ...apiObj,
                    url:path,
                    ...(apiObj.method=="get" && {params:params}),
                    ...(apiObj.method=="post" && {data:params}),
                  });
              }
          }
       }
       return list;
   },
});
