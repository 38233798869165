import Vue from 'vue'
import axios from 'axios'
import createManger from '@/biz/index.js';
import https from 'https';


function handleData(res){
	if (res.success) {
	   return res;
	} else {
	    let err= new Error("数据获取失败");
      if(res){
        let message=res.msg || "";
        err = new Error(message);
        err.response = res;
      }
	    return Promise.reject(err);
	}
}

export default function ({ $axios }, inject) {
   function axiosConfig($axios){
      let baseUrl="https://rest.apizza.net/mock/a3428dde22215d5149d1e460f3ec08b6";
      if(process.server){
         baseUrl="https://rest.apizza.net/mock/a3428dde22215d5149d1e460f3ec08b6";
      }else if(process.env.NODE_ENV === 'development'){
         baseUrl="/api";
      }

      $axios.setBaseURL(baseUrl);

       $axios.interceptors.request.use(function(config) {
       //	console.log("request请求:",config.url);
       	// 在发送请求之前做些什么
       	return config;
       }, function(error) {
       	// 对请求错误做些什么
       	return Promise.reject(error);
       });

       // 添加响应拦截器
       $axios.interceptors.response.use(function(response) {
      	 //console.log("response响应:",response.config.url,response.data);
       	// 对响应数据做点什么
       	return handleData(response.data);
       }, function(error) {
       	// 对响应错误做点什么
       	return Promise.reject(error);
       });
       return $axios;
   }

   const axiosInstance = axiosConfig($axios.create({
       httpsAgent: new https.Agent({
          rejectUnauthorized:false,// process.env.NODE_ENV === 'development'? false : true, //Nuxt.js框架进行HTTPS请求时，Nuxt.js框架试图验证服务器提供的SSL/TLS证书链，但是失败了。这可能是因为服务器使用了自签名证书，或者证书链中的某个证书不被客户端信任。
       })
   }));
   inject('request', createManger(axiosInstance))

};
